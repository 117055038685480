// Imports
import { useRef, useState, useEffect } from 'react';

// Components
import BarChart from '../BarChart/BarChart';

// CSS
import './ActivationsOverTimeChart.css';

const ActivationsOverTimeChart = ({
    activityOverTimeData, 
    startDate: startTimestamp, 
    colors, 
    timeUnit = "month", 
    chartHeight = 180, 
    barMargin = 2 
}) => {
    
    // MARK:Properties

    // MARK: - States

    // Coordinate system
    
    // - X-axis
    const [xAxisValues, setXAxisValues] = useState([]);
    const [xAxisTicks, setXAxisTicks] = useState([]);
    const [mondays, setMondays] = useState([]);
    const [x_lines, setXLines] = useState([]);
    
    // - Y-axis
    const [maxValue, setMaxValue] = useState(0);
    const [yAxisValues, setYAxisValues] = useState([]);
    
    // Bar chart
    const [data, setData] = useState([]);
    const [dataWithUndefinedInMiddle, setDataWithUndefinedInMiddle] = useState([]);
    const [colorsWithUndefinedInMiddle, setColorsWithUndefinedInMiddle] = useState([]);
        
    // MARK: - Refs

    const barChartRef = useRef(null);

    // MARK: - Constants

    // MARK: Functions

    const updateXLabelsFromList = (list) => {
        // Set the x-axis values to month names (short), e.g. Jan, Feb, Mar, ...
        const listIndeces = Array.from({length: list.length}, (_, i) => i);
        const xAxisValuesNewValue = listIndeces.map((_, i) => list[i]);
        setXAxisValues(xAxisValuesNewValue);
        
        updateXAxisTicks(listIndeces, startTimestamp);
    }

    const roundToMultipleAboveOfPowerOfTenBelow = (number) => {
        const numberString = Math.abs(number).toString()
        const indexOfDecimalPoint = numberString.indexOf(".")
        const digitCount = indexOfDecimalPoint >= 0 ? indexOfDecimalPoint : numberString.length
        const firstDigit = parseInt(numberString[0])
        const roundedUpFirstDigit = firstDigit + 1
        const powerOfTen = Math.pow(10, digitCount - 1)

        const roundNumber = (Math.sign(number) * roundedUpFirstDigit * powerOfTen);
        return roundNumber
    }

    const differenceFromRoundNumber = (number) => {
        const roundNumber = roundToMultipleAboveOfPowerOfTenBelow(number)
        return Math.abs(number - roundNumber)
    }

    const findOptimalYTicks = (maxValue, possibleTickCounts) => {
        const roundMax = roundToMultipleAboveOfPowerOfTenBelow(maxValue);

        var bestTicks
        var bestDifference

        for (var i = 1; i <= possibleTickCounts.length; i++) {
            const tickCount = possibleTickCounts[i]
            const tickInterval = roundMax / tickCount
            const diff = differenceFromRoundNumber(tickInterval)
            if (!bestDifference || diff < bestDifference) {
                bestDifference = diff
                bestTicks = tickCount
            }
        }

        return Array.from({length: bestTicks}, (_, i) => Math.round(roundMax / (bestTicks - 1) * i)).reverse();
    }

    const updateXAxisTicks = (_xAxisValues, startDate) => {
        const widthThresholds = [1200]

        // Calculate mondays
        var mondays = [];
        for (var i = 0; i < _xAxisValues.length; i++) {
            var date = new Date(startDate);
            date.setDate(date.getDate() + i);
            if (date.getDay() === 1) {
                mondays.push(i);
            }
        }
        
        setMondays(mondays);

        if ((barChartRef.current && barChartRef.current.offsetWidth > widthThresholds[0])
            || timeUnit === "week"
            || timeUnit === "year"
        ) {
            setXAxisTicks(_xAxisValues);
        } else {
            setXAxisTicks(mondays);
        }
    }

    const barChartDataFrom = (data) => {
        var output = []
        if (data.length > 0 && data[0].counts.length > 0) {
             // TODO: Invert the data structure somewhere at the fetcher level so that it does not have to be inverted each time it is used.
            for (var dayIndex = 0; dayIndex < data[0].counts.length; dayIndex++) {
                var day = []
                for (var categoryIndex = 0; categoryIndex < data.length; categoryIndex++) {
                    const category = data[categoryIndex].counts[dayIndex]
                    day.push(category)
                }
                output.push(day)
            }
        }
        
        return output
    }

    const getXLabelClass = (value) => {
        return (xAxisTicks.indexOf(value) >= 0) ? "x-label" : "x-label-hidden"
    }

    // MARK: useEffects

    useEffect(() => {

        // Set the data with undefined in the middle
        if (activityOverTimeData && activityOverTimeData.length > 0) {
            const indexOfUndefined = activityOverTimeData.findIndex((element) => element.category === "UNDEFINED");

            if (indexOfUndefined == -1) {
                setDataWithUndefinedInMiddle(activityOverTimeData);
                setColorsWithUndefinedInMiddle(colors);
                return;
            }

            const middleIndex = Math.floor((activityOverTimeData.length - 1) / 2);

            var rearrangedList = [];
            var rearrangedColorList = [];

            if (indexOfUndefined == middleIndex) {
                rearrangedList = activityOverTimeData;
                rearrangedColorList = colors;
            } else {
                for (var i = 0; i < activityOverTimeData.length; i++) {
                    var sourceIndex;

                    // Swap the middle and the undefined values.
                    if (i == middleIndex) {
                        sourceIndex = indexOfUndefined;
                    } else if (i == indexOfUndefined) {
                        sourceIndex = middleIndex;
                    }
                    // Continue around the swapped values.
                    else if (i > indexOfUndefined && i < middleIndex) {
                        sourceIndex = i + 1;
                    } else {
                        sourceIndex = i;
                    }

                    rearrangedList.push(activityOverTimeData[sourceIndex]);
                    rearrangedColorList.push(colors[sourceIndex]);
                }
            }

            setDataWithUndefinedInMiddle(rearrangedList);
            setColorsWithUndefinedInMiddle(rearrangedColorList);
        }
    }, [activityOverTimeData]);

    useEffect(() => {
        if (dataWithUndefinedInMiddle && dataWithUndefinedInMiddle.length > 0) {
            // Set the max value for the y-axis
            var max = 0;

            if (dataWithUndefinedInMiddle[0] == undefined) {
                return
            }

            for (var dayIndex = 0; dayIndex < dataWithUndefinedInMiddle[0].counts.length; dayIndex++) {
                var sum = 0;

                for (var categoryIndex = 0; categoryIndex < dataWithUndefinedInMiddle.length; categoryIndex++) {
                    sum += dataWithUndefinedInMiddle[categoryIndex].counts[dayIndex];
                }

                if (sum > max) {
                    max = sum;
                }
            }


            // Set the y-axis values
            const yTicks = findOptimalYTicks(max, [2, 3, 4, 5, 6]);
            const roundedMax = yTicks[0];
            setMaxValue(roundedMax);
            setYAxisValues(yTicks);
            
            const dataNewValue = barChartDataFrom(dataWithUndefinedInMiddle)
            setData(dataNewValue)
        }
    }, [dataWithUndefinedInMiddle]);

    useEffect(() => {
        // Set the x-axis values
        if (dataWithUndefinedInMiddle && dataWithUndefinedInMiddle.length > 0) {

            if (dataWithUndefinedInMiddle[0] != undefined) {
                const xAxisValuesNewValue = Array.from({length: dataWithUndefinedInMiddle[0].counts.length}, (_, i) => i + 1)
                // setXAxisValues(xAxisValuesNewValue);
                //updateXAxisTicks(xAxisValuesNewValue, startDate);
            }
        }

    }, [dataWithUndefinedInMiddle, startTimestamp]);


    useEffect(() => {
        if (timeUnit == "month") {
            setXLines(mondays);
        } else {
            setXLines([]);
        }
    }, [mondays, timeUnit]);

    useEffect(() => {
        switch (timeUnit) {
            case "week":
                // Set the x-axis values to weekdays (short), e.g. Mon, Tue, Wed, ...
                const weekdayNames = ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"];
                updateXLabelsFromList(weekdayNames)

                break;
            case "month":
                // Get days in month
                if (startTimestamp) {
                    const startDate = new Date(startTimestamp);
                    const firstDayInNextMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
                    const firstDayInMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                    const timeDelta = firstDayInNextMonth - firstDayInMonth;
                    const daysInMonth = timeDelta / (1000 * 60 * 60 * 24);
                    console.log("debug - 1 - daysInMonth", daysInMonth)
                    console.log("debug - 1 - startDate", startDate)
                    const dayNumbers = Array.from({length: daysInMonth}, (_, i) => i + 1);
                    updateXLabelsFromList(dayNumbers)
                }

                break;
            case "year":
                // Set the x-axis values to month names (short), e.g. Jan, Feb, Mar, ...
                const monthNames = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];
                updateXLabelsFromList(monthNames)

                break;
            default:
                break;
        }
    }, [timeUnit, startTimestamp]);

    // MARK: Return

    return (
        <div className="ActivationsOverTimeChart">
            <div className="barchart-grid">
                <div className="y-axis-container">
                    <div className="y-axis">
                        {yAxisValues.map((value, index) => (
                            <div key={index} className="y-label-container">
                                <div className="y-label-anchor">
                                    <div className="y-label">

                                        {value}

                                        <div className="y-label-width-filler">
                                            {maxValue}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="y-axis-width-filler">
                        {maxValue}
                    </div>
                </div>
                <div 
                    ref={barChartRef}
                    className="barchart-cell"
                    style={{"height": chartHeight}}
                >
                    <div className="y-lines">
                        {yAxisValues.map((_, index) => (
                            <div 
                                key={index} 
                                className="y-line" 
                                style={{
                                    "bottom": chartHeight/(yAxisValues.length - 1) * index
                                }}>
                            </div>
                        ))}
                    </div>

                    <div className="x-lines">
                        {xAxisValues.map((value, index) => (
                            <div 
                                key={index} 
                                className="x-line" 
                                style={{
                                    "left": 20/(xAxisValues.length - 1) * index,
                                    "visibility":  x_lines.includes(index) ? "visible" : "hidden"
                                }}
                            >
                            </div>
                        ))}
                        <div className="x-lines-right-filler">
                        </div>
                    </div>

                    <div
                        className="bar-chart"
                    >
                        <BarChart
                            data={data}
                            colors={colorsWithUndefinedInMiddle}
                            barMargin={barMargin}
                            graphMaxValue={maxValue}
                        />
                    </div>
                </div>
                <div className="filler">
                </div>

                <div className="x-axis">
                    {xAxisValues.map((value, index) => (
                        <div 
                            key={index}
                            className="x-label-container"
                            style={{
                                "marginLeft": barMargin,
                                "marginRight": barMargin
                            }}
                        >
                            <div className="x-label-anchor">
                                <div className={getXLabelClass(index)}>
                                    {value}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ActivationsOverTimeChart;