import React, { createContext, useState, useEffect, useContext } from 'react';
import { useUnitDataCache } from './UnitDataCacheContext';
import { useThirdHeader } from './ThirdHeaderContext';
import { useSecondHeader } from './SecondHeaderContext';

// Create the context
const UnitDataParserContext = createContext();

// Create a provider component
export const UnitDataParserProvider = ({ children }) => {
    const { showUserEvaluations } = useThirdHeader();
    const { currentUnit } = useSecondHeader();
    const {
        activationsCounts,
        activityOverTimeData,
        activityHeatmapData,
        categoryGroupedRecords,
        heatmapRecords,
        barchartRecords
    } = useUnitDataCache(); 

    const [categoryGrouped, setCategoryGrouped] = useState([]);

    const [activationsData, setActivationsData] = useState([]);
    const [donutData, setDonutData] = useState([]);
    const [heatmapData, setHeatmapData] = useState([]);
    const [barchartData, setBarchartData] = useState([]);

    const [barChartTimeUnit, setBarChartTimeUnit] = useState("year");
    const [barChartFrom, setBarChartFrom] = useState(0);

    const [votedCategoriesCategoryGrouped, setVotedCategoriesCategoryGrouped] = useState([]);
    const [votedCategoriesHeatmapData, setVotedCategoriesHeatmapData] = useState([]);
    const [votedCategoriesBarchartData, setVotedCategoriesBarchartData] = useState([]);

    const [categoryNames, setCategoryNames] = useState([]);
    const [colorForCategory, setColorForCategory] = useState({});

    // Colors

    const [colors] = useState([
        '#74D5F2',
        '#BD92F4',
        '#81F3D5',
        '#FF8989',
        '#81ECF3',
        '#53C0FF',
        '#ED9BD2',
        '#FF89B0',
        '#90AAFF',
        '#81F3AD'
    ]);

    const special_categories = ["Kan ikke vurderes",]
    const specified_colors = {
        [special_categories[0]]: "#BFBFBF",
        "Ikke vurderet": "#D0D0D0",
        "UNDEFINED": "#A5A5A5"
    }
    const defaultColor = "#A5A5A5"

    const getColorForCategory = (category) => {
        return colorForCategory[category] ?? defaultColor
    }

    const getVotedCategories = (records, completion, defaultToMachine = true) => {
        const votedCategoriesNewValue = records.map((record) => {
            if(record.user_evals) {
                const users_voted = Object.keys(record.user_evals)
                var votesForCategory = {}
                var highestVoted;
                for (let i = 0; i < users_voted.length; i++) {
                    const category = record.user_evals[users_voted[i]]
                    if (category && category !== undefined && category !== null) {
                        if (votesForCategory[category]) {
                            votesForCategory[category] += 1
                        } else {
                            votesForCategory[category] = 1
                        }
        
                        if (highestVoted !== category) {
                            if(!highestVoted || votesForCategory[category] > votesForCategory[highestVoted]) {
                                highestVoted = category
                            } else if (votesForCategory[category] === votesForCategory[highestVoted]) {
                                highestVoted = "Uafgjort"
                            }
                        }
                    }
                }
    
                if (highestVoted) {
                    if(highestVoted === "Uafgjort") {
                        console.log("Uafgjort: ", record)
                    }
                    
                    return highestVoted
                }
            }
    
            return defaultToMachine ? record.eval : undefined
        })

        completion(votedCategoriesNewValue)
    }

    useEffect(()=>{
        if(categoryGroupedRecords) {
            const defaultToMachine = true
            getVotedCategories(categoryGroupedRecords, setVotedCategoriesCategoryGrouped, defaultToMachine)
        }
    }, [categoryGroupedRecords])

    useEffect(()=>{
        if (heatmapRecords) {   
            getVotedCategories(heatmapRecords, setVotedCategoriesHeatmapData)
        }
    }, [heatmapRecords])

    useEffect(()=>{
        if (barchartRecords) {
            getVotedCategories(barchartRecords, setVotedCategoriesBarchartData)
        }
    }, [barchartRecords])

    const updateCategoryGroupedWithUserEvaluations = (includeNonEvaluated = true) => {
        var activationsDataNewValue = []

        if (currentUnit) {
                
            // Add standard categories
            if (currentUnit.eval_categories) {
                for (var i = 0; i < currentUnit.eval_categories.length; i++) {
                    const category = currentUnit.eval_categories[i]
                    activationsDataNewValue.push({ category: category, count: 0 })
                }
            }

            // Add user added categories

            if (currentUnit.custom_categories) {
                for (let i = 0; i < currentUnit.custom_categories.length; i++) {
                    const category = currentUnit.custom_categories[i]
                    activationsDataNewValue.push({ category: category, count: 0 })
                }
            }
        }

        for (let i = 0; i < categoryGroupedRecords.length; i++) {
            let category = votedCategoriesCategoryGrouped[i]
            if (!category) {
                if (includeNonEvaluated) {
                    category = "Ikke vurderet"
                } else {
                    continue
                }
            }
            const categoryIndex = activationsDataNewValue.findIndex(
                (element) => element.category === category
            )

            if (categoryIndex === -1) {
                activationsDataNewValue.push({ category: category, count: 1 })
            } else {
                activationsDataNewValue[categoryIndex].count += 1
            }
        }

        // Put category with name "UNDEFINED" at the end of the default categories
        const defaultCategoriesCount = currentUnit.eval_categories.length
        const undefinedTargetIndex = defaultCategoriesCount - 1
        const undefinedSourceIndex = activationsDataNewValue.findIndex((element) => element.category === "UNDEFINED")
        if (undefinedSourceIndex !== -1) {
            const undefinedCategory = activationsDataNewValue.splice(undefinedSourceIndex, 1)[0]
            activationsDataNewValue.splice(undefinedTargetIndex, 0, undefinedCategory)
        }

        setCategoryGrouped(activationsDataNewValue)
    }

    const updateHeatmapDataWithUserEvaluations = () => {
        var heatmapDataNewValue = []

        if (currentUnit) {
                
            // Add standard categories
            if (currentUnit.eval_categories) {
                for (let i = 0; i < currentUnit.eval_categories.length; i++) {
                    const category = currentUnit.eval_categories[i]
                    let counts = []
                    for (let j = 0; j < 7; j++) {
                        counts.push(0)
                    }
                    heatmapDataNewValue.push({ category: category, counts: counts })
                }
            }

            // Add user added categories

            if (currentUnit.custom_categories) {
                for (let i = 0; i < currentUnit.custom_categories.length; i++) {
                    const category = currentUnit.custom_categories[i]
                    let counts = []
                    for (let j = 0; j < 7; j++) {
                        counts.push(0)
                    }
                    heatmapDataNewValue.push({ category: category, counts: counts })
                }
            }
        }

        // Add counts

        for (let i = 0; i < heatmapRecords.length; i++) {
            const category = votedCategoriesHeatmapData[i] ?? "Ikke vurderet"
            const categoryIndex = heatmapDataNewValue.findIndex((element) => element.category === category)

            // Find week day of record
            const timestamp = heatmapRecords[i].timestamp
            const day = new Date(timestamp).getDay()
            const mondayIndexed = day === 0 ? 6 : day - 1

            let counts = []
            for (let j = 0; j < 7; j++) {
                if (j === mondayIndexed) {
                    counts.push(1)
                } else {
                    counts.push(0)
                }
            }

            if (categoryIndex === -1) {
                heatmapDataNewValue.push({ category: category, counts: counts })
            } else {
                heatmapDataNewValue[categoryIndex].counts[mondayIndexed] += 1
            }
        }

        setHeatmapData(heatmapDataNewValue)
    }

    function getDaysInMonth(date) {
        const month = date.getMonth();
        const year = date.getFullYear();
        return new Date(year, month + 1, 0).getDate();
    }

    const updateActivityOverTimeDataWithUserEvaluations = () => {
        var activityOverTimeDataNewValue = []

        const firstDate = new Date(barChartFrom)
        var timeUnitMaxValue = 0
        console.log("switching time unit, : ", barChartTimeUnit)
        switch (barChartTimeUnit) {
            case "year":
                timeUnitMaxValue = 12
                break;
            case "month":
                timeUnitMaxValue = getDaysInMonth(firstDate)
                break;
            case "week":
                timeUnitMaxValue = 7
                break;
            default:
                break;
        }

        if (currentUnit) {
                
            // Add standard categories
            if (currentUnit.eval_categories) {
                for (let i = 0; i < currentUnit.eval_categories.length; i++) {
                    const category = currentUnit.eval_categories[i]
                    var counts = []
                    for (var j = 0; j < timeUnitMaxValue; j++) {
                        counts.push(0)
                    }
                    activityOverTimeDataNewValue.push({ category: category, counts: counts })
                }
            }

            // Add user added categories

            if (currentUnit.custom_categories) {
                for (let i = 0; i < currentUnit.custom_categories.length; i++) {
                    const category = currentUnit.custom_categories[i]
                    var counts = []
                    for (let j = 0; j < timeUnitMaxValue; j++) {
                        counts.push(0)
                    }
                    activityOverTimeDataNewValue.push({ category: category, counts: counts })
                }
            }
        }
        
        for (let i = 0; i < barchartRecords.length; i++) {
            //console.log("Switch- i: ", i)
            const category = votedCategoriesBarchartData[i] ?? "Ikke vurderet"
            //console.log("Switch- category: ", category)
            const categoryIndex = activityOverTimeDataNewValue.findIndex((element) => element.category === category)
            
            const timestamp = barchartRecords[i].timestamp
            var timeWithinUnit = 0
            const date = new Date(timestamp)
            switch (barChartTimeUnit) {
                case "year":
                    timeWithinUnit = date.getMonth()
                    break;
                case "month":
                    timeWithinUnit = date.getDate() - 1
                    break;
                case "week":
                    const sundayIndexed = date.getDay()
                    timeWithinUnit = sundayIndexed === 0 ? 6 : sundayIndexed - 1
                    break;
                default:
                    break;
            }

            //console.log("Switch- timeWithinUnit:", timeWithinUnit)
            if (categoryIndex === -1) {
                let _counts = []
                //console.log("Switch- 1 filling loop start ")
                //console.log("Switch- 1 timeUnitMaxValue: ", timeUnitMaxValue)
                for (let k = 0; k < timeUnitMaxValue; k++) {
                    if (k === timeWithinUnit) {
                        _counts.push(1)
                    } else {
                        _counts.push(0)
                    }
                }
                //console.log("Switch counts(loop done): ", counts)
                //console.log("Switch- 2 categoryIndex: ", categoryIndex)
                activityOverTimeDataNewValue.push({ category: category, counts: _counts })
                //console.log("Switch- counts: ", counts)
            } else {
               /*console.log("Switch- categoryIndex: ", categoryIndex)
                console.log("Switch- timeWithinUnit: ", timeWithinUnit)
                console.log("Switch- activityOverTimeDataNewValue[categoryIndex]: ", activityOverTimeDataNewValue[categoryIndex])
                console.log("Switch- activityOverTimeDataNewValue[categoryIndex].counts: ", activityOverTimeDataNewValue[categoryIndex]["counts"])
                console.log("Switch- activityOverTimeDataNewValue[categoryIndex].counts[x]: ", activityOverTimeDataNewValue[categoryIndex].counts[timeWithinUnit])
                */
                activityOverTimeDataNewValue[categoryIndex].counts[timeWithinUnit] += 1
                //console.log("Switch- activityOverTimeDataNewValue[categoryIndex].counts 2: ", activityOverTimeDataNewValue[categoryIndex].counts[timeWithinUnit])
            }
        }

        console.log("Setting barchart data: ", activityOverTimeDataNewValue)
        setBarchartData(activityOverTimeDataNewValue)
    }

    useEffect(() => {
        if (showUserEvaluations) {
            const includeNonEvaluated = false
            updateCategoryGroupedWithUserEvaluations(includeNonEvaluated)
        }
    }, [votedCategoriesCategoryGrouped, showUserEvaluations])

    useEffect(() => {
        if (showUserEvaluations) {
            updateHeatmapDataWithUserEvaluations()
        }
    }, [votedCategoriesHeatmapData, showUserEvaluations])

    useEffect(() => {
        if (showUserEvaluations) {
            updateActivityOverTimeDataWithUserEvaluations()
        }
    }, [votedCategoriesBarchartData, showUserEvaluations])

    useEffect(() => {
        if (!showUserEvaluations && activationsCounts) {
            setCategoryGrouped(activationsCounts);
        }
    }, [activationsCounts, showUserEvaluations])

    useEffect(() => {
        if (!showUserEvaluations && activityHeatmapData) {
            setHeatmapData(activityHeatmapData);
        }
    }, [activityHeatmapData, showUserEvaluations])

    useEffect(() => {
        if(!showUserEvaluations && activityOverTimeData) {
            setBarchartData(activityOverTimeData);
        }
    }, [activityOverTimeData, showUserEvaluations])

    useEffect(() => {
        if (categoryGrouped) {
            setActivationsData(categoryGrouped);
            setDonutData(categoryGrouped);
        }
    }, [categoryGrouped])

    useEffect(() => {
        if (categoryNames && colors) {
            const colorForCategoryNewValue = {}
            for (var i = 0; i < categoryNames.length; i++) {
                const category = categoryNames[i]
                if (!specified_colors[category]) {
                    colorForCategoryNewValue[category] = colors[i % colors.length]
                }
            }

            for(let key in specified_colors) {
                colorForCategoryNewValue[key] = specified_colors[key]
            }

            setColorForCategory(colorForCategoryNewValue)
        }
    }, [colors, categoryNames])

    useEffect(() => {
        if (currentUnit) {
            const _categoryNames = currentUnit.eval_categories.concat(currentUnit.custom_categories)
            setCategoryNames(_categoryNames)
        }
    }, [currentUnit]);

    return (
        <UnitDataParserContext.Provider value={{
            activationsData,
            donutData,
            heatmapData,
            barchartData,

            getColorForCategory,

            setBarChartTimeUnit,
            setBarChartFrom
            
        }}>
            {children}
        </UnitDataParserContext.Provider>
    );
};

export const useUnitDataParser = () => {
    return useContext(UnitDataParserContext);
};
