
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  da: {
    translation: {
      "YTD": "ÅTD",

      "UNDEFINED": "Andet",
      "NORMAL": "Normal",
      "IMPREGNATED": "Imprægneret",
      "SOFT_PLASTIC": "Bl\u00f8d plast",
      "HARD_PLASTIC": "Hård plast",

      "hard_pvc": "Hård PVC",
      "hard_plastic": "Hård plast",
      "impregnated_wood": "Imprægneret træ",
      "pvc_plastic": "PVC plast",
      "soft_plastic": "Blød plast",

      "Sat": "L\u00f8r",
      "Sun": "S\u00f8n",

      "tree1": {
          "UNDEFINED": "Andet",
          "NORMAL": "Normal",
          "IMPREGNATED": "Imprægneret"
      },
      "plastic1": {
          "UNDEFINED": "Andet",
          "SOFT_PLASTIC": "Blød plast",
          "HARD_PLASTIC": "Hård plast"
      },
      "test": {
          "an-eval": "Imprægneret",
          "another-eval": "Eval 2"
      },

      "DASHBOARD": {
          "ACTIVATIONS_?": "Hvis den nuværende periode er valgt, bliver den tidligere periode indstillet til samme længde.",
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "da", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;