import React, { useState, useEffect } from 'react';
import { useUnitDataCache } from '../../../../contexts/UnitDataCacheContext';
import { useSecondHeader } from './../../../../contexts/SecondHeaderContext';
//import { Dialog } from '@material-ui/core'; // Assuming you're using Material-UI
import DatePickerComponent from '../../../../components/DatePicker/DatePicker';
import './ImagesSubPage.css'; // Import the styles
import { useParams } from 'react-router-dom';
import RecordDialog from './RecordDialog/RecordDialog.jsx';
import { Modal } from '@mui/material';
import { DialogRecordProvider } from './RecordDialog/RecordDialogContext';
import { useTranslation } from 'react-i18next';

import down_arrow from "./../../../../assets/icons/down-arrow.svg"
import { useUnitDataParser } from '../../../../contexts/UnitDataParserContext.js';

const ImagesSubPage = () => {
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { unitId } = useParams();
    const { records, getRecords } = useUnitDataCache();
    const [ localRecords, setLocalRecords ] = useState([]);
    const { currentUnit, getUnitData } = useSecondHeader();
    const { getColorForCategory } = useUnitDataParser();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [activeFilter, setActiveFilter] = useState('');
    const [flipSort, setFlipSort] = useState(false);
    const [showPagePaginatorDropdown, setShowPagePaginatorDropdown] = useState(false);
    const [modalInitialIndex, setModalInitialIndex] = useState(0);
    
    // Defaulto to year to date
    const defaultStartUnix = (new Date(new Date().getFullYear(), 0, 1)).getTime();
    const defaultEndUnix = (new Date()).getTime();

    const [startUnix, setStartUnix] = useState(defaultStartUnix);
    const [end_unix, setEndUnix] = useState(defaultEndUnix);

    const [votedCategoryForRecords, setVotedCategoryForRecords] = useState([]);


    const totalPages = () => {
        //console.log("Total pages called")
        //console.log("localRecords.length", localRecords.length)
        //console.log("pageSize", pageSize)
        return Math.ceil(localRecords.length / pageSize)
    }

    // On mount
    useEffect(() => {
        //console.log("Images page - Fetching data... (on mount)");

        if (!currentUnit) {
            //console.log("Fetching unit data...", unitId);
            //console.log("Unit id:")
            //console.log(unitId)
            getUnitData(unitId)
        }

        getRecords(unitId, defaultStartUnix, defaultEndUnix);
    }, []);

    useEffect(() => {
        filterRecords()
    }, [records, activeFilter, flipSort]);

    useEffect(() => {
        //console.log("localRecords useEffect", localRecords)
        const votedCategories = localRecords.map((record) => {
            if(record.user_evals) {
                const users_voted = Object.keys(record.user_evals)
                var votesForCategory = {}
                var highestVoted;
                for (let i = 0; i < users_voted.length; i++) {
                    const category = record.user_evals[users_voted[i]]
                    if (votesForCategory[category]) {
                        votesForCategory[category] += 1
                    } else {
                        votesForCategory[category] = 1
                    }

                    if(!highestVoted || votesForCategory[category] > votesForCategory[highestVoted]) {
                        highestVoted = category
                    }
                }

                if (highestVoted) {
                    return highestVoted
                }
            }

            return "Ikke vurderet"
        })

        setVotedCategoryForRecords(votedCategories)
    }, [localRecords]);

    /*
    useEffect(() => {
        //console.log("currentImgIndex useEffect", currentImgIndex)
        const record = records[currentImgIndex];
        setCurrentRecordX(record);
    }, [currentImgIndex])*/

    const getVotingForItem = (item) => {
        if(item.user_evals) {
            const users_voted = Object.keys(item.user_evals)
            var votesForCategory = {}
            var highestVoted;
            for (let i = 0; i < users_voted.length; i++) {
                const category = item.user_evals[users_voted[i]]
                if (votesForCategory[category]) {
                    votesForCategory[category] += 1
                } else {
                    votesForCategory[category] = 1
                }

                if(!highestVoted || votesForCategory[category] > votesForCategory[highestVoted]) {
                    highestVoted = category
                }
            }

            if (highestVoted) {
                return highestVoted
            }
        }

        return item.eval
    }

    const filterRecords = () => {
        //console.log("filterRecords called")
        var data = records.filter((elem) => activeFilter === '' ? true : getVotingForItem(elem) === activeFilter);
        if (flipSort) data = data.reverse();
        setLocalRecords(data);
    }

    const handleIndexChange = (newIndex) => {
        //setCurrentIndex(newIndex);
        //console.log('Current image index changed:', newIndex);
        //setCurrentRecordX(localRecords[newIndex]);
        // Add any additional logic you need here when the index changes

        // Refresh records in order to see the newest user evals.
        getRecords(unitId, startUnix, end_unix);
    };

    const handleSortChange = () => {
        setFlipSort(flipSort === false);
        setCurrentPage(0);
        filterRecords();
    };

    const handleDatePicked = async (start_unix, end_unix) => {
        setStartUnix(start_unix);
        setEndUnix(end_unix);
        await getRecords(unitId, start_unix, end_unix);
    };

    const handlePaginatorClick = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handlePaginatorDropdownClick = () => {
        setShowPagePaginatorDropdown(!showPagePaginatorDropdown);
    };

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const nextPage = () => {
        //console.log("currentPage", currentPage)
        //console.log("totalPages", totalPages())
        if (currentPage < totalPages() - 1) setCurrentPage(currentPage + 1);
    };

    const openImageDialog = (record, index) => {
        const adjustedIndex = flipSort ? localRecords.length - 1 - index : index;
        setModalInitialIndex(adjustedIndex);
        setIsDialogOpen(true);

        // Refresh records in order to see the newest user evals.
        getRecords(unitId, startUnix, end_unix);
    };

    const closeDialog = () => {
        //setCurrentRecordX(undefined);
        setIsDialogOpen(false);
    };

    const getCellClassName = (index, side) => {
        var className = "cdk-cell";

        const lastInPage = Math.min(pageSize, localRecords.length - currentPage * pageSize);
        if (index === 0) {
            className += " first-row-" + side;
        } else if (index === lastInPage - 1) {
            className += " last-row-" + side;
        } else if (side === "right") {
            className += " right-cell"
        }
        
        return className;
    }

    const getVotedCategoryForRecords = (index) => {
        if (!votedCategoryForRecords[index] || votedCategoryForRecords[index] === "Ikke vurderet") {
            return ""
        }

        return votedCategoryForRecords[index]
    }

    const getFormattedDate = (timestamp) => {
        const formattedDate = new Intl.DateTimeFormat('da-DK', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }).format(new Date(timestamp));
          
          // Reformatting to "YYYY-MM-DD"
          const [day, month, year] = formattedDate.split('.');
          const formattedDateISO = `${year}-${month}-${day}`;

          return formattedDateISO;
    }

    return (
      <div className="ImagesSubPage">
        <div className="outer-wrapper">
            <div className="main-wrapper">
                <div className="records-tabs">
                    <div className="category-buttons">
                        <button 
                            className="aris-button-secondary" 
                            onClick={() => setActiveFilter('')}
                            style={{
                                color: activeFilter === "" ? "var(--gray-900)" : "#fff",
                                backgroundColor: activeFilter === "" ? "var(--primary-green-500)" : ''
                            }}
                        >
                            Alle
                        </button>
                        {currentUnit?.eval_categories?.map((category, i) => (
                            <button
                                key={category}
                                className="aris-button-secondary"
                                onClick={() => setActiveFilter(category)}
                                style={{
                                    color: activeFilter === category ? "var(--gray-900)" : "#fff",
                                    backgroundColor: activeFilter === category ? getColorForCategory(activeFilter) : ''
                                }}
                            >
                                {t(category)}
                            </button>
                        ))}

                        {currentUnit?.custom_categories?.map((category, i) => (
                            <button
                                key={category}
                                className="aris-button-secondary"
                                onClick={() => setActiveFilter(category)}
                                style={{
                                    color: activeFilter === category ? "var(--gray-900)" : "#fff",
                                    backgroundColor: activeFilter === category ? getColorForCategory(activeFilter) : ''
                                }}
                            >
                                {t(category)}
                            </button>
                        ))}
                    </div>

                    <button className="sort-button" onClick={handleSortChange}>
                        <span>{flipSort ? "Ældste først" : "Nyeste først"}</span>
                        <div className="date-picker-toggle-arrow sort-arrow">
                            <img
                                src={down_arrow}
                                alt="Sort Arrow"
                                style={{ transform: flipSort ? 'rotate(180deg)' : '' }}
                            />
                        </div>
                    </button>
                    <DatePickerComponent
                        onDateSelected={handleDatePicked}
                    />
                </div>
                <div className="records-table">
                    <table>
                        <thead>
                            <tr className="card-header">
                                <th
                                    style={{
                                        paddingLeft: '4px',
                                    }}
                                >
                                        Dato
                                </th>
                                <th>Dag</th>
                                <th>Tid</th>
                                <th>Kategori</th>
                                <th>Bruger evaluering</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {localRecords
                                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                                .map((element, index) => (
                                    <tr key={element.timestamp}>
                                        <td className={getCellClassName(index, "left")}>
                                            {getFormattedDate(element.timestamp)}
                                        </td>
                                        <td className="cdk-cell" style={{ textTransform: 'capitalize' }}>
                                            {new Intl.DateTimeFormat('da-DK', { weekday: 'short' }).format(new Date(element.timestamp))}
                                        </td>
                                        <td className="cdk-cell" >{new Date(element.timestamp).toLocaleTimeString('da-DK').replace(/\./g, ':')}</td>
                                        <td className="cdk-cell"
                                            style={{
                                                color: getColorForCategory(element.eval)
                                            }}
                                        >
                                            {t(`${element.eval}`)}
                                        </td>
                                        <td className="cdk-cell"
                                            style={{
                                                color: getColorForCategory(getVotedCategoryForRecords(index))
                                            }}
                                        >
                                            {t(getVotedCategoryForRecords(index))}
                                        </td>
                                        <td className={getCellClassName(index, "right")}>
                                            <button
                                                className="aris-button-primary"
                                                style={{ fontSize: '14px', lineHeight: '14px', padding: '9px 12px' }}
                                                onClick={() => openImageDialog(element, pageSize * currentPage + index)}
                                            >
                                                Se billede
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div className="table-footer">
                        {localRecords.length > 0 && (
                            <div className="page-paginator-dropdown-wrapper" onClick={handlePaginatorDropdownClick}>
                                <div className="page-paginator-text">Pr. side: {pageSize}</div>
                                <div className="page-paginator-dropdown-toggle">
                                    <img
                                        className={`tiny-icon ${showPagePaginatorDropdown ? 'page-paginator-dropdown-toggle-on' : ''}`}
                                        src={down_arrow}
                                        alt="."
                                    />
                                </div>
                                {showPagePaginatorDropdown && (
                                    <div className="page-paginator-dropdown">
                                        {[10, 20, 50, 100].map((size) => (
                                            <div key={size} className="page-paginator-dropdown-elem" onClick={() => handlePaginatorClick(size)}>
                                                {size}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="page-paginator-navigator">
                            <div
                                className={`page-paginator-navigator-elem ${currentPage === 0 ? 'page-paginator-navigator-elem-disabled' : ''}`}
                                onClick={prevPage}
                            >
                                <img className="page-paginator-left-arrow" src={down_arrow} alt="." />
                            </div>
                            <div className="page-paginator-navigator-current">
                                {currentPage * pageSize} -{' '}
                                {currentPage === totalPages() - 1 ? localRecords.length : (currentPage + 1) * pageSize} / {localRecords.length}
                            </div>
                            <div
                                className={`page-paginator-navigator-elem ${currentPage === totalPages() - 1 ? 'page-paginator-navigator-elem-disabled' : ''}`}
                                onClick={nextPage}
                            >
                                <img className="page-paginator-right-arrow" src={down_arrow} alt="." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DialogRecordProvider 
            onIndexChange={handleIndexChange}
        >
            <Modal
                open={isDialogOpen}
                onClose={closeDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <RecordDialog
                        isOpen={isDialogOpen}
                        initialIndex={modalInitialIndex}
                    />
                </>
            </Modal>
        </DialogRecordProvider>
      </div>
    );
};

export default ImagesSubPage;
